.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: #8767f0;
  height: 100vh;
  overflow: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.heroBanner {
  padding: 2rem 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 24px;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.codeWrapper {
  width: 100%;
  height: auto;
  padding: 32px;
  text-align: left;
}

.responsePage {
  display: flex;
  text-align: left;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  /* min-height: 100vh; */
  background-color: white;
  padding: 8px;
  padding-top: 24px;
}

.fifty {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media screen and (max-width: 1160px) {
  .heroBanner {
    flex-direction: column;
    box-sizing: content-box;
  }

  .codeWrapper {
    padding: 8px;
    font-size: 10px;
  }

  .responsePage {
    flex-direction: column;
    padding-top: 48px;
  }
  
  .fifty {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

h1 {
  margin: 0;
  margin-bottom: 1rem;
}

h4{
  margin: 0;
  font-size: 1rem;
}

p {
  margin: 0;
  margin-bottom: 1.25rem;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  box-sizing: border-box;
}

.hero__subtitle {
  font-size: 1.5rem;
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 8px 32px;
  background-color: #ebedf0;
  border-radius: 0.4rem;
  font-size: 1.25rem;
  font-weight: 700;
  max-width: 314px;
}

* {
  box-sizing: border-box;
}

.footer {
  width: 100%;
  padding: 48px;
  background-color: #303846;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

